import adminPicture from "@/axios/adminPicture";

export const create = async (picture, productId) => {
  let formData = new FormData();
  formData.append("file", picture.file);
  formData.append("alt", picture.alt);
  formData.append("isMain", picture.main);
  formData.append("productId", productId);

  await adminPicture.post("/pictures", formData);
};
