export const getToken = () => {
  return sessionStorage.getItem("token");
};

export const getName = () => {
  if (sessionStorage.getItem("name") !== null) {
    return sessionStorage.getItem("name");
  }
  return null;
};

export const removeAuthenticationItems = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("name");
  sessionStorage.removeItem("isAdmin");
  localStorage.removeItem("token");
};

export const saveAuthenticationItems = (name, isAdmin, token) => {
  sessionStorage.setItem("name", name);
  sessionStorage.setItem("isAdmin", isAdmin);
  sessionStorage.setItem("token", token);
};
