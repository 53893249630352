import axios from "axios";
import router from "@/router";
import PATH_URL from "@/utils/environnements.js";
import { getToken } from "@/utils/token.js";
import { toastMessage, multipleErrorToaster } from "@/utils/toaster.js";

const adminAxios = axios.create({
  baseURL: PATH_URL + "/api/v1",
  timeout: 120000,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

// On intercepte la request
adminAxios.interceptors.request.use(function (config) {
  // Choses à faire avant d'envoyer la request
  config.headers.Authorization = "Bearer " + getToken();
  return config;
});

// On intercepte la response
adminAxios.interceptors.response.use(
  (response) => {
    // Toute réponse ayant un status 2xx déclenche cette fonction
    // Choses à faire avec la réponse
    return response;
  },
  (error) => {
    // Toute réponse ayant un status différent de 2xx déclenche cette fonction
    // Choses à faire avec l'erreur
    if (error.response?.status == 401) {
      // Si c'est une erreur d'authentification
      toastMessage(error.response.data.message, "is-danger");
      router.push({ name: "admin-login" });
    } else if (error.response?.data?.details) {
      // Si c'est une erreur contenant des détails
      multipleErrorToaster(error.response.data.details);
    } else {
      // Toute autre erreur
      toastMessage(
        "Une erreur s'est produite, veuillez réessayer ultérieurement",
        "is-danger"
      );
    }
    return Promise.reject(error);
  }
);

export default adminAxios;
