import axios from "axios";
import PATH_URL from "@/utils/environnements.js";
import { toastMessage } from "@/utils/toaster.js";

const backendAxios = axios.create({
  baseURL: PATH_URL + "/api",
  // baseURL: "https://api.sophieetstitch.fr/api",
  timeout: 120000,
});

// On intercepte la response
backendAxios.interceptors.response.use(
  (response) => {
    // Toute réponse ayant un status 2xx déclenche cette fonction
    // Choses à faire avec la réponse
    return response;
  },
  (error) => {
    // Toute réponse ayant un status différent de 2xx déclenche cette fonction
    // Choses à faire avec l'erreur
    toastMessage(
      "Une erreur s'est produite, veuillez réessayer ultérieurement",
      "is-danger"
    );
    return Promise.reject(error);
  }
);

export default backendAxios;
