import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
import LegalNoticesView from "@/views/LegalNoticesView.vue";
import LoginView from "@/views/admin/LoginView";
import AdminView from "@/views/admin/AdminView";
import CreationsView from "@/views/CreationsView";
import ProductsView from "@/views/admin/ProductsView";
import { getToken } from "@/utils/token";
import MaintenanceView from "@/views/MaintenanceView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/legal",
    name: "legalNotice",
    component: LegalNoticesView,
  },
  {
    path: "/creations",
    name: "creation",
    component: CreationsView,
  },
  {
    path: "/cours",
    name: "cours",
    component: MaintenanceView,
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
    children: [
      { path: "login", name: "admin-login", component: LoginView },
      {
        path: "products",
        name: "admin-products",
        component: ProductsView,
        beforeEnter: (to, from, next) => {
          if (to.name !== "admin-login" && getToken() == null)
            next({ name: "admin-login" });
          else next();
        },
      },
      { path: "*", redirect: { name: "home" } },
    ],
  },
  { path: "*", redirect: { name: "home" } },
];

const router = new VueRouter({
  routes,
});

export default router;
