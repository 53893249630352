<template>
  <b-modal
    v-model="isComponentModalActive"
    @close="closeModal()"
    :width="640"
    scroll="keep"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <p v-if="id == null" class="modal-card-title">Création d'un article</p>
        <p v-else class="modal-card-title">Modification d'un article</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Titre" label-position="on-border">
          <b-input
            type="text"
            id="title"
            v-model="title"
            maxlength="150"
            required
            validation-message="Le titre est obligatoire"
          >
          </b-input>
        </b-field>

        <b-field label="Description" label-position="on-border">
          <b-input
            type="textarea"
            id="description"
            v-model="description"
            maxlength="2000"
          >
          </b-input>
        </b-field>
        <b-field label="Lien Etsy" label-position="on-border">
          <b-input type="text" id="etsyLink" v-model="etsyLink" maxlength="255">
          </b-input>
        </b-field>

        <!-- TAGS affectation -->
        <div style="height: 8em">
          <b-field grouped group-multiline>
            <div v-for="tag in productTags" :key="tag.id" class="control">
              <b-tag
                type="is-info"
                attached
                aria-close-label="Close tag"
                closable
                @close="deleteTag(tag)"
              >
                {{ tag.code }}
              </b-tag>
            </div>
          </b-field>
          <b-field>
            <b-autocomplete
              v-model="tagName"
              :data="codeTags"
              placeholder="Affectez vos tags"
              icon="magnify"
              clearable
              @select="(option) => affectTags(option)"
            >
              <template #empty>Aucun résultat trouvé</template>
            </b-autocomplete>
          </b-field>
        </div>
        <span @click="isFavorite = !isFavorite" class="pr-6">
          <font-awesome-icon
            icon="fa-solid fa-star"
            class="icon is-medium"
            :class="[isFavorite ? 'has-text-warning' : 'has-text-dark']"
          />
        </span>

        <span>
          <b-switch
            type="is-success"
            id="isPublished"
            v-model="isPublished"
            :disabled="
              isPicturePresent() || !title || !description || id == null
            "
          >
            Publié
          </b-switch>
        </span>

        <!-- UPLOAD picture -->
        <UploadPicture
          @updatePictures="updatePictures"
          @pictureRemoved="checkImageListAndSetPublished"
          :savedPicturesRead="product ? product.pictures : null"
          @updateSavedPictures="updateSavedPictures"
        />
      </section>
      <footer class="modal-card-foot">
        <b-button
          v-if="id != null"
          label="Modifier"
          type="is-primary"
          @click="update"
          :disabled="!canSave"
        />
        <b-button
          @click="confirmDelete"
          v-if="id != null"
          type="is-primary"
          :disabled="isAdmin == 'false'"
        >
          <font-awesome-icon
            class="icon is-medium"
            icon="fa-solid fa-trash-can"
          />
        </b-button>
        <b-button
          v-else
          @click="create"
          label="Créer"
          type="is-primary"
          :disabled="!canSave"
        />
      </footer>
    </div>
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
  </b-modal>
</template>

<script>
import * as productApi from "@/api/product";
import * as pictureApi from "@/api/picture";
import { toastMessage } from "@/utils/toaster";
import UploadPicture from "@/views/admin/components/UploadPicture.vue";

export default {
  name: "CreateProductModal",
  components: {
    UploadPicture,
  },
  props: {
    // Toute donnée entrante du composant (il n'a que les droit de 'lecture')
    isModalActive: Boolean,
    id: Number,
    tags: [],
  },
  data() {
    // Toute donnée propres au composant (il a tous les droits dessus)
    return {
      isAdmin: false,
      title: "",
      description: "",
      etsyLink: "",
      isFavorite: false,
      isPublished: false,
      isComponentModalActive: false,
      product: null,
      uploadedPictures: [],
      isLoading: false,
      // Tags
      productTags: [],
      tagName: "",
      choosenCodeTags: [],
    };
  },
  created() {},
  watch: {
    // On 'surveille' isModalActive : dès qu'on détecte un changement de valeur, on exécute la fonction
    isModalActive(newValue) {
      if (this.id != null) {
        this.isAdmin = sessionStorage.getItem("isAdmin");
        this.selectById(this.id);
      }
      this.isComponentModalActive = newValue;
    },
    description() {
      if (this.description == "") {
        this.isPublished = false;
      }
    },
  },
  methods: {
    checkImageListAndSetPublished() {
      if (!this.product && this.uploadedPictures.length == 0) {
        this.isPublished = false;
      }
      if (
        this.product &&
        this.uploadedPictures.length == 0 &&
        this.product.pictures.length == 0
      ) {
        this.isPublished = false;
      }
    },

    isPicturePresent() {
      if (!this.product) {
        return this.uploadedPictures.length == 0;
      } else {
        if (this.product.pictures) {
          return (
            this.uploadedPictures.length == 0 &&
            this.product.pictures.length == 0
          );
        }
      }
    },

    updatePictures(event) {
      this.uploadedPictures = event;
    },

    updateSavedPictures(event) {
      if (this.product) {
        this.product.pictures = event;
      }
    },

    // on réinitialise le product et on envoi le close dans la Vue parente
    closeModal() {
      this.isLoading = false;
      this.changeProductValues("", "", "", false, false);
      this.product = null;
      this.productTags = [];
      this.uploadedPictures = [];
      this.tagName = "";
      this.$emit("close");
      this.isComponentModalActive = false;
    },

    async create() {
      this.isLoading = true;
      try {
        // Traitement du produit
        this.product = await productApi.create(
          this.title,
          this.description,
          this.etsyLink,
          this.isFavorite,
          this.isPublished,
          this.productTags
        );
        // Traitement des images affectées
        await this.uploadPictures();
      } finally {
        this.isLoading = false;
        this.closeModal();
        this.$emit("resetProducts");
      }
    },

    async update() {
      this.isLoading = true;
      try {
        // MAJ du produit (comprend la suppression des images)
        await productApi.update(
          this.id,
          this.title,
          this.description,
          this.etsyLink,
          this.isFavorite,
          this.isPublished,
          this.product.pictures,
          this.productTags
        );
        // Ajouter les images
        await this.uploadPictures();
      } finally {
        this.isLoading = false;
        this.closeModal();
        this.$emit("resetProducts");
      }
    },

    async selectById() {
      this.isLoading = true;
      try {
        this.product = await productApi.findById(this.id);
        if (this.product == null) {
          this.$emit("resetProducts");
          this.closeModal();
        }
        this.changeProductValues(
          this.product.title,
          this.product.description,
          this.product.etsyLink,
          this.product.isFavorite,
          this.product.isPublished
        );
        this.productTags = !this.product.tags ? [] : this.product.tags;
        if (!this.product.pictures) {
          this.product.pictures = [];
        }
      } finally {
        this.isLoading = false;
      }
    },

    async deleteById() {
      this.isLoading = true;
      try {
        await productApi.deleteById(this.id);
      } finally {
        this.isLoading = false;
        this.closeModal();
        this.$emit("resetProducts");
      }
    },

    confirmDelete() {
      if (this.isAdmin === "true") {
        this.$buefy.dialog.confirm({
          message: "Êtes-vous sûr de vouloir supprimer le produit ?",
          cancelText: "Annuler",
          confirmText: "Oui, supprimer",
          onConfirm: () => this.deleteById(),
        });
      } else {
        toastMessage(
          "Vous n'êtes pas autorisé à effectuer cette action",
          "is-danger"
        );
      }
    },

    /** Valorise les données du produit */
    changeProductValues(title, description, etsyLink, isFavorite, isPublished) {
      this.title = title;
      this.description = description;
      this.etsyLink = etsyLink;
      this.isFavorite = isFavorite;
      this.isPublished = isPublished;
    },

    async uploadPictures() {
      for (let picture of this.uploadedPictures) {
        await pictureApi.create(picture, this.product.id);
      }
    },

    /** Affecte un tag selon son code selectionné */
    affectTags(codeSelected) {
      if (codeSelected === null) return;
      // on retrouve le tag
      let tagSelected = this.tags.find((tag) => tag.code === codeSelected);
      let tagAlreadySelected = this.productTags.find(
        (pt) => pt.code === codeSelected
      );
      // on l'ajoute au produit si il n'y est pas déjà
      if (tagAlreadySelected) return;
      this.productTags.push(tagSelected);
    },

    /** Retire un tag du produit */
    deleteTag(tag) {
      let tagToDeleteIndex = this.productTags.indexOf(tag);
      this.productTags.splice(tagToDeleteIndex, 1);
    },
  },
  computed: {
    /** valorise la liste des codes des tags selon la saisie de l'usager */
    codeTags() {
      return this.tags
        .map((tag) => tag.code)
        .filter((code) => {
          let codeStr = code.toLowerCase();
          let userWriting = this.tagName.toLowerCase();
          return codeStr.indexOf(userWriting) >= 0;
        });
    },

    canSave() {
      return this.title && !this.isAltNull;
    },

    isAltNull() {
      for (let picture of this.uploadedPictures) {
        if (!picture.alt) {
          return true;
        }
      }
      if (this.product) {
        if (this.product.pictures) {
          for (let picture of this.product.pictures) {
            if (!picture.alt) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
};
</script>
