<template>
  <div class="container">
    <!-- LOGO -->
    <MainHeader />
    <main class="m-2">
      <div class="columns">
        <!-- TITRE -->
        <div
          class="column is-9 is-flex is-align-items-center is-justify-content-center"
        >
          <h1 class="title is-1">Créations au crochet</h1>
        </div>
        <!-- FILTRES -->
        <div class="column is-3">
          <b-field class="my-5">
            <b-autocomplete
              v-model="tagName"
              :data="codeTags"
              :disabled="isInputDisabled"
              placeholder="Recherchez par thème"
              icon="magnify"
              clearable
              @select="(option) => addFilterTag(option)"
            >
              <template #empty>Aucun résultat trouvé</template>
            </b-autocomplete>
          </b-field>
        </div>
      </div>
      <!-- LISTE DE TAGS FILTRES -->
      <div class="is-flex is-flex-direction-row-reverse tagArea">
        <b-field grouped group-multiline>
          <div v-for="tag in filterTags" :key="tag.id" class="control">
            <b-tag
              type="is-info is-light"
              attached
              aria-close-label="Close tag"
              closable
              @close="removeTagFilter(tag)"
            >
              {{ tag.code }}
            </b-tag>
          </div>
        </b-field>
      </div>

      <!-- PRODUITS AVEC IMAGE 480x320-->
      <div class="columns is-multiline">
        <div
          v-for="product in products"
          :key="product.id"
          class="column is-3 pdt"
          @click="showProduct(product)"
        >
          <figure class="image is-3by2">
            <img :src="getProductImg(product)" :alt="product.alt" />
          </figure>
        </div>
      </div>

      <!-- PAGINATION -->
      <div class="is-flex is-align-items-center is-justify-content-center my-6">
        <b-button @click="getProductByPage" :disabled="isDisplayMore"
          >Voir plus</b-button
        >
      </div>

      <!-- FENETRE DE DIALOGUE -->
      <b-modal v-model="isCardProductActive" :width="640" scroll="keep">
        <div class="card">
          <div class="card-image">
            <b-carousel :indicator-inside="false" :interval="7500">
              <b-carousel-item
                v-for="(item, i) in productToDisplay.srcImages.length"
                :key="i"
              >
                <b-image
                  class="image"
                  :src="productToDisplay.srcImages[i]"
                ></b-image>
              </b-carousel-item>
              <template #indicators="props">
                <b-image
                  class="al image"
                  :src="productToDisplay.srcImages[props.i]"
                ></b-image>
              </template>
            </b-carousel>
          </div>
          <div class="card-content">
            <div class="content has-text-left">
              <p class="title is-4">{{ productToDisplay.title }}</p>
              <p>{{ productToDisplay.description }}</p>
              <br />
              <p>
                Une commande ? Une idée ? Une personnalisation ? Contactez moi !
              </p>
              <a
                class="mx-2"
                :href="
                  Links.MAIL_TO +
                  '?subject=Question concernant le produit ' +
                  productToDisplay.title
                "
                ><font-awesome-icon
                  class="icon is-medium"
                  icon="fa-solid fa-envelope"
                  color="#535353"
              /></a>
              <a
                v-if="productToDisplay.etsyLink !== undefined"
                class="mx-2"
                :href="productToDisplay.etsyLink"
                target="_blank"
                ><font-awesome-icon
                  class="icon is-medium"
                  icon="fa-brands fa-etsy"
                  color="#535353"
              /></a>
            </div>
          </div>
        </div>
      </b-modal>
    </main>
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from "@/views/components/MainHeader.vue";
import MainFooter from "@/views/components/MainFooter.vue";
import * as tagApi from "@/api/tag";
import * as productApi from "@/api/product";
import ConstantsLinks from "@/utils/ConstantsLinks.js";

export default {
  name: "CreationsView",
  components: {
    MainHeader,
    MainFooter,
  },

  data() {
    return {
      // Constantes
      Links: ConstantsLinks,
      FILTER_TAGS_LIMIT: 5,
      // Liste des tags
      tags: [],
      // Liste des produits
      products: [],
      // Pagination
      pagination: {
        currentPage: 1,
        totalPages: 1,
      },
      // data des recherches
      filterTags: [],
      tagName: "",
      // Recherche multicritère
      filters: {
        tagCodes: "",
      },
      // modal
      isCardProductActive: false,
      productToDisplay: {
        srcImages: [],
        title: "",
        description: "",
        etsyLink: "",
      },
    };
  },

  created() {
    this.allProducts();
    this.allTags();
  },

  watch: {
    /** Ecoute du filtre des tags */
    filters: {
      async handler(newValue) {
        if (newValue == null || newValue == undefined) return;
        this.pagination.currentPage = 1;
        await this.allProducts();
      },
      deep: true,
    },
  },

  methods: {
    /** Charge les tags */
    async allTags() {
      let response = [];
      let isPublished = true;
      response = await tagApi.findAll(isPublished);
      this.tags = response;
    },

    /** Ajoute un tag sur les filtres de recherche */
    addFilterTag(option) {
      if (option === null) return;
      if (this.isInputDisabled) return;
      let tagChoosen = this.tags.find((tag) => tag.code === option);
      // on l'ajoute aux filtres s'il est absent
      let tagAlreadyChoosen = this.filterTags.find(
        (tag) => tag.code === option
      );
      if (tagAlreadyChoosen) return;
      // prévisualisation des tags souhaités
      this.filterTags.push(tagChoosen);
      // filtre de recherche
      this.filters.tagCodes = this.filterTags.map((ft) => ft.code).join();
    },

    /** Retire un tag du filtre de recherche */
    removeTagFilter(tag) {
      if (tag === null) return;
      let tagToDeleteIndex = this.filterTags.indexOf(tag);
      // prévisualisation des tags souhaités
      this.filterTags.splice(tagToDeleteIndex, 1);
      // filtre de recherche
      this.filters.tagCodes = this.filterTags.map((ft) => ft.code).join();
    },

    /** Rajoute la nouvelle page de produit */
    async getProductByPage() {
      if (this.pagination.currentPage >= this.pagination.totalPages) return;
      this.pagination.currentPage += 1;
      await this.allProducts();
    },

    /** Charge les produits */
    async allProducts() {
      let paginatedResponse = await productApi.findAllToWeb(
        this.filters,
        this.pageIndex
      );
      if (paginatedResponse.ressources == undefined) {
        this.products = [];
        this.resetFilters();
        this.resetPagination();
        return;
      }
      // Gestion de la Pagination
      this.pagination.currentPage = paginatedResponse.currentPage + 1;
      this.pagination.totalPages = paginatedResponse.totalPages;
      if (this.pagination.currentPage === 1) {
        this.products = paginatedResponse.ressources;
      } else {
        this.products = this.products.concat(paginatedResponse.ressources);
      }
    },

    /** Réinitialise la pagination */
    resetPagination() {
      this.pagination = {
        currentPage: 1,
        totalPages: 1,
      };
    },

    /** Réinitialise les filtres de recherches de produits */
    resetFilters() {
      // Vérification si les filtres sont déjà réinitialisés
      if (
        this.filters.isFavorite === false &&
        (this.filters.isPublished === null ||
          this.filters.isPublished === false) &&
        this.filters.search === "" &&
        this.filters.codeTags === ""
      )
        return;
      // Réinitialisation des filtres et de la pagination
      this.filters = {
        isFavorite: false,
        isPublished: null,
        search: "",
        codeTags: "",
      };
      this.filterTags = [];
      this.pagination.currentPage = 1;
    },

    /** format la source de l'image */
    getProductImg(product) {
      if (product.pictures.length === 0) return null;
      return (
        "data:" +
        product.pictures[0].type +
        ";base64," +
        product.pictures[0].data
      );
    },

    /**  */
    getImageSrc(img) {
      return "data:" + img.type + ";base64," + img.data;
    },

    /** Affiche le produit dans une fenêtre de dialogue */
    showProduct(product) {
      if (product === null || product === undefined) return;
      this.productToDisplay.srcImages = product.pictures.map((p) =>
        this.getImageSrc(p)
      );
      this.productToDisplay.description = product.description;
      this.productToDisplay.title = product.title;
      this.productToDisplay.etsyLink = product.etsyLink;
      this.isCardProductActive = true;
    },
  },

  computed: {
    /** valorise la liste des codes des tags selon la saisie de l'usager */
    codeTags() {
      return this.tags
        .map((tag) => tag.code)
        .filter((code) => {
          let codeStr = code.toLowerCase();
          let userWriting = this.tagName.toLowerCase();
          return codeStr.indexOf(userWriting) >= 0;
        });
    },

    /** Convertie la page en cours en index pour l'API backend */
    pageIndex() {
      return this.pagination.currentPage - 1;
    },

    /** Désactive le boutton "Voir Plus" */
    isDisplayMore() {
      return (
        this.pagination.currentPage >= this.pagination.totalPages ||
        this.pagination.currentPage < 0
      );
    },

    /** Désactive le boutton "Voir Plus" */
    isInputDisabled() {
      return this.filterTags.length >= this.FILTER_TAGS_LIMIT;
    },
  },
};
</script>

<style lang="scss">
// Zone des filtres des tags
.tagArea {
  height: 4em;
}
// effet de selection d'un produit
.pdt :hover {
  cursor: pointer;
  opacity: 0.9;
}
// Carousel pour visualiser les images du produit sélectionné
a.is-active > figure.al > img {
  filter: grayscale(0%);
}
a > figure.al > img {
  filter: grayscale(100%);
}
figure.al > img {
  height: 3em;
}
</style>
