import backendAxios from "@/axios/backend";
import * as tokenUtils from "@/utils/token.js";
import * as toaster from "@/utils/toaster.js";

export const login = async (name, password) => {
  const data = {
    name,
    password,
  };
  const response = await backendAxios
    .post("/login", data)
    .then((response) => {
      tokenUtils.saveAuthenticationItems(
        response.data.name,
        response.data.isAdmin,
        response.headers.authorization.split(" ")[1]
      );
      toaster.toastMessage(
        "Bonjour " + tokenUtils.getName() + " 😀",
        "is-success"
      );
    })
    .catch(function (error) {
      //si le back retourne une erreur
      if (error.response) {
        toaster.toastMessage(error.response.data.message, "is-danger");
      } else {
        //dans tous les autres cas :
        toaster.toastMessage(
          "Une erreur s'est produite, veuillez réessayer ultérieurement",
          "is-danger"
        );
      }
    });
  return response;
};
