<template>
  <footer class="mt-6 mb-3">
    <div class="mx-3">
      <a class="mx-2" :href="Links.INSTA_LINK" target="_blank"
        ><font-awesome-icon
          class="icon is-medium"
          icon="fa-brands fa-instagram"
          color="#535353"
      /></a>

      <a class="mx-2" :href="Links.FB_LINK" target="_blank"
        ><font-awesome-icon
          class="icon is-medium"
          icon="fa-brands fa-facebook"
          color="#535353"
      /></a>

      <a class="mx-2" :href="Links.ETSY_LINK" target="_blank"
        ><font-awesome-icon
          class="icon is-medium"
          icon="fa-brands fa-etsy"
          color="#535353"
      /></a>

      <a
        class="mx-2"
        :href="
          Links.MAIL_TO + '?subject=Bonjour, j\'aimerai échanger avec vous'
        "
        ><font-awesome-icon
          class="icon is-medium"
          icon="fa-solid fa-envelope"
          color="#535353"
      /></a>
    </div>
    <router-link :to="{ name: 'legalNotice' }">Mentions legales</router-link>
    <p>© 2022 par Sophie & stitch</p>
  </footer>
</template>
<script>
import ConstantsLinks from "@/utils/ConstantsLinks.js";

export default {
  name: "MainFooter",
  data() {
    return { Links: ConstantsLinks };
  },
};
</script>
