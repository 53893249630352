import adminAxios from "@/axios/admin";
import * as toaster from "@/utils/toaster.js";

export const create = async ({ label, code }) => {
  const params = {
    label,
    code,
  };
  let tag;
  await adminAxios.post("/tags", params).then((response) => {
    tag = response.data;
    toaster.toastMessage(
      "Le tag " + response.data.code + " a été créé 😀",
      "is-success"
    );
  });
  return tag;
};

export const findAll = async (isPublished) => {
  const params = {
    isPublished,
  };
  let tags;
  await adminAxios.get("/tags", { params }).then((response) => {
    tags = response.data;
  });
  return tags;
};

export const findById = async (id) => {
  let tag;
  await adminAxios.get("/tags/" + id).then((response) => {
    tag = response.data;
  });
  return tag;
};

export const update = async ({ id, code, label, isFavorite, isPublished }) => {
  const data = {
    id,
    code,
    label,
    isFavorite,
    isPublished,
  };
  await adminAxios.put("/tags/" + id, data).then((response) => {
    toaster.toastMessage(
      "Le tag " + response.data.code + " a été modifié 😀",
      "is-success"
    );
  });
};

export const deleteById = async (id) => {
  if (sessionStorage.getItem("isAdmin") == "false") {
    toaster.toastMessage(
      "Vous n'êtes pas autorisé à effectuer cette action",
      "is-danger"
    );
  } else {
    await adminAxios.delete("/tags/" + id).then(() => {
      toaster.toastMessage("Le tag a été supprimé 😀", "is-success");
    });
  }
};
