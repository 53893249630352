<template>
  <div>
    <b-table
      :data="tags"
      :loading="this.isLoading"
      striped
      focusable
      default-sort="id"
      default-sort-direction="desc"
    >
      <!-- Colonne id -->
      <b-table-column
        field="id"
        label="ID"
        width="0"
        numeric
        v-slot="list"
        sortable
      >
        {{ list.row.id }}
      </b-table-column>
      <!-- Colonne code -->
      <b-table-column field="code" label="CODE" v-slot="list">
        <b-field
          ><b-input
            :disabled="!isAdmin"
            type="text"
            v-model="list.row.code"
            minlength="2"
            maxlength="30"
            size="is-small"
            required
            validation-message="2-30 caractères"
            @blur="changedValue(list.row, 'code')"
          >
          </b-input
        ></b-field>
      </b-table-column>
      <!-- Colonne label -->
      <b-table-column field="label" label="LABEL" v-slot="list">
        <b-field
          ><b-input
            :disabled="!isAdmin"
            type="text"
            v-model="list.row.label"
            minlength="2"
            maxlength="50"
            size="is-small"
            required
            validation-message="2-50 caractères"
            @blur="changedValue(list.row, 'label')"
          >
          </b-input
        ></b-field>
      </b-table-column>
      <!-- Colonne actions -->
      <b-table-column v-slot="list" width="150">
        <span>
          <b-switch
            v-if="list.row.id"
            :disabled="!isAdmin"
            :value="list.row.isPublished"
            class="mx-1"
            type="is-success"
            size="is-small"
            @input="changedValue(list.row, 'isPublished')"
          ></b-switch>
        </span>
        <span>
          <b-button
            v-if="list.row.id"
            :disabled="!isAdmin"
            class="mx-1"
            size="is-small"
            type="is-light"
            @click="changedValue(list.row, 'isFavorite')"
          >
            <font-awesome-icon
              icon="fa-solid fa-star"
              :class="[
                list.row.isFavorite
                  ? 'icon is-small has-text-warning'
                  : 'icon is-small has-text-dark',
              ]"
          /></b-button>
        </span>
        <span>
          <b-button
            v-if="list.row.id"
            :disabled="!isAdmin"
            class="mx-1"
            size="is-small"
            type="is-light"
            @click="confirmDelete(list.row.id)"
          >
            <font-awesome-icon
              class="icon is-small"
              icon="fa-solid fa-trash-can"
          /></b-button>
        </span>
        <span>
          <b-button
            v-if="!list.row.id"
            :disabled="!isAdmin"
            class="mx-1"
            size="is-small"
            type="is-light"
            @click="createTag(list.row)"
          >
            <font-awesome-icon class="icon is-small" icon="fa-solid fa-plus"
          /></b-button>
        </span>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import * as tagApi from "@/api/tag";
import * as toaster from "@/utils/toaster.js";

export default {
  name: "TagList",
  components: {},
  props: {
    tags: [],
  },

  data() {
    return {
      isLoading: false,
      isAdmin: false,
    };
  },
  created() {
    this.isAdmin = sessionStorage.getItem("isAdmin");
  },
  methods: {
    /** Ajout / modification d'un tag */
    async changedValue(tag, field) {
      if (!tag.id) {
        return;
      }
      // Modification du tag
      if (field === "isFavorite" || field === "isPublished") {
        tag[field] = !tag[field];
      }
      try {
        this.isLoading = true;
        await tagApi.update(tag);
      } finally {
        this.isLoading = false;
      }
    },

    async createTag(newElement) {
      if (!newElement.label || !newElement.code) return;
      let newTag = { code: newElement.code, label: newElement.label };
      try {
        this.isLoading = true;
        newTag = await tagApi.create(newTag);
        this.tags.push(newTag);
      } finally {
        this.isLoading = false;
      }
    },

    /** Fenêtre de dialogue de confirmation de suppression d'un tag */
    confirmDelete(id) {
      this.$buefy.dialog.confirm({
        message: "Êtes-vous sûr de vouloir supprimer le tag ?",
        cancelText: "Annuler",
        confirmText: "Oui, supprimer",
        onConfirm: () => this.deleteById(id),
      });
    },

    /** Requête de demande de suppression backend du tag selon son id */
    async deleteById(id) {
      let isOnError = false;
      // Requête de suppression du tag
      try {
        this.isLoading = true;
        await tagApi.deleteById(id);
      } catch (error) {
        isOnError = true;
        toaster.toastMessage(error.response.data.message, "is-danger");
      } finally {
        this.isLoading = false;
      }
      // Suppression si pas d'erreur d'affectation du tag par des produits
      if (!isOnError) {
        const tagToDelete = this.tags.find((tag) => tag.id === id);
        const tagToDeleteIndex = this.tags.indexOf(tagToDelete);
        this.tags.splice(tagToDeleteIndex, 1);
      }
    },
  },
};
</script>
