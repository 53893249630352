// Environnements de DEV & PROD (API Backend)
const PATH_URL = {
  DEV: "http://localhost:8080",
  PROD: "https://api.sophieetstitch.fr",
};

/**
 * Constante pour choisir l'environnement souhaité.
 * DEV : pointe vers l'API REST en local.
 * PROD : pointe vers le sous-domaine de l'API REST de production.
 */
export default PATH_URL.PROD;
