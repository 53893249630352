<template>
  <div class="container">
    <MainHeader />
    <main class="m-2">
      <h1 class="title is-1">Créations au crochet</h1>
      <p class="title is-4 is-italic has-text-weight-light">
        fait main - peluches - déco - matières bio ou recylées
      </p>
      <div class="columns">
        <div
          class="column is-4"
          v-for="activity in activities"
          :key="activity.title"
        >
          <div class="flag" @click="goToPage(activity.goTo)">
            <b-image
              :src="activity.image"
              :alt="activity.alt"
              ratio="6by4"
            ></b-image>
          </div>
          <div class="columns mt-3">
            <div class="column is-8 is-offset-2">
              <p class="title is-4">{{ activity.title }}</p>
              <p class="has-text-justified">
                {{ activity.description }}
                <a :href="activity.link" target="_blank"
                  ><span v-if="activity.linkText != undefined">
                    {{ activity.linkText }}</span
                  ><font-awesome-icon
                    :icon="activity.icon"
                    v-if="activity.icon != undefined"
                  />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="columns mt-5">
        <div class="column is-6 is-offset-3">
          <h1 class="title is-1">Qui est Sophie ?</h1>
          <h3 class="title is-5">une crocheteuse amoureuse</h3>
          <div class="has-text-justified">
            <p class="content">
              J'aime offrir des cadeaux fait main. J'ai créé des cadeaux de
              naissance, d'anniversaire, ou tout simplement pour dire "Je
              t'aime". Quel plaisir de réaliser un objet de ses propres mains et
              de voir les yeux scintillants des receveurs.
            </p>
            <p class="content">
              Lors de ma grossesse, il me fallait trouver une activité que je
              puisse faire depuis mon canapé. La trousse de crochet est facile à
              transporter, à sortir, à ranger, elle est légère et passe partout
              ! L'activité idéale pour une femme enceinte en quête de
              créativité. J'ai donc appris le crochet en 2016, sans jamais
              m'arrêter.
            </p>
            <em
              >J'ai créé Sophie & stitch en Janvier 2022 pour pouvoir vous faire
              profiter de mes créations ♥
            </em>
          </div>
        </div>
      </div>
      <div class="columns is-vcentered mt-5">
        <div class="column is-5 is-offset-1">
          <h1 class="title is-1">Environnement</h1>
          <h3 class="title is-5">De simples gestes comptent</h3>
          <div class="has-text-justified">
            <p>
              Chacun de mes choix prend en compte le bien être de notre planète.
            </p>
            <p>
              Zéro déchets de fil : les fils coupés sont utilisés pour
              rembourrer des amigurumis, et les pelotes retrouvées dans les
              fonds de tiroirs sont transformées, ne serait-ce qu'en petite
              fleur ❀
            </p>
            <p>
              Des matériaux bios, recyclés, moins gourmands en resources, moins
              néfastes. <br />
              Des circuits courts : j'achète ma laine dans la mercerie du coin,
              et je vends mes créations localement.
            </p>
          </div>
        </div>
        <div class="column is-5">
          <b-image :src="image" alt="un champs de coton" ratio="6by4"></b-image>
        </div>
      </div>
    </main>
    <MainFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import MainHeader from "@/views/components/MainHeader.vue";
import MainFooter from "@/views/components/MainFooter.vue";
import ConstantsLinks from "@/utils/ConstantsLinks.js";

export default {
  name: "HomeView",
  components: {
    MainHeader,
    MainFooter,
  },
  data() {
    return {
      Links: ConstantsLinks,
      image: require("@/assets/coton.webp"),
      boutique: require("@/assets/boutique.webp"),
      personnalisation: require("@/assets/personnalisation.webp"),
      cours: require("@/assets/cours.webp"),
      // routes
      ROUTE_NAME_CREATION: "creation",
      ROUTE_NAME_COURS: "cours",
    };
  },

  methods: {
    /** Navigation de la home vers les autres pages */
    goToPage(option) {
      if (option === null || undefined) return;
      this.$router.push({ name: option });
    },
  },

  computed: {
    activities: function () {
      return [
        {
          image: require("@/assets/boutique.webp"),
          alt: "sacs de shopping",
          title: "Boutique",
          description:
            "Mes créations sont disponibles en vente directe, ou en ligne dans ma boutique",
          link: this.Links.ETSY_LINK,
          icon: "fa-brands fa-etsy",
          goTo: this.ROUTE_NAME_CREATION,
        },
        {
          image: require("@/assets/personnalisation.webp"),
          alt: "mains qui font du crochet",
          title: "Personnalisations",
          description:
            "Il est tout à fait possible de commander un produit personnalisé !",
          link:
            this.Links.MAIL_TO +
            "?subject=Bonjour, je vous contacte au sujet des personnalisations",
          linkText: "Il vous suffit de tout m'expliquer.",
          goTo: this.ROUTE_NAME_CREATION,
        },
        {
          image: require("@/assets/cours.webp"),
          alt: "une multitude de pelotes de laine",
          title: "Cours",
          description:
            "Je peux vous enseigner le crochet. Un moment créatif, dans un bain de pelotes, armés de nos crochets,",
          link:
            this.Links.MAIL_TO +
            "?subject=Bonjour, je vous contacte au sujet des cours",
          linkText: "écrivez-moi ",
          icon: "fa-solid fa-at",
          goTo: this.ROUTE_NAME_COURS,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.flag :hover {
  cursor: pointer;
  opacity: 0.9;
}
</style>
