<template>
  <div>
    <!-- NAVBAR -->
    <b-navbar class="border-bottom">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/creations' }">
          <b-image
            id="admin-logo-navbar"
            alt="Sophie & stitch"
            :src="require('@/assets/logo.png')"
          />
        </b-navbar-item>
      </template>
      <template #end>
        <b-navbar-item tag="div" class="has-text-left has-text-weight-bold">
          Bonjour, {{ getName() }}
        </b-navbar-item>
        <b-navbar-item tag="div">
          <b-button type="is-light" size="is-medium" @click="logout">
            <font-awesome-icon
              icon="fa-solid fa-right-from-bracket"
              class="icon is-medium"
            />
          </b-button>
        </b-navbar-item>
      </template>
    </b-navbar>

    <h1 class="title my-6">Sophie & stitch</h1>

    <!-- CONTENU -->
    <div class="container is-widescreen">
      <div class="columns mx-1">
        <!-- COLONNE DE PRODUITS -->
        <div class="column is-7">
          <!-- FILTRES - COTE GAUCHE -->
          <div class="columns my-2">
            <div
              class="column is-8 is-flex is-align-items-center is-flex-wrap-wrap"
            >
              <b-switch
                :value="false"
                type="is-warning"
                v-model="filters.isFavorite"
                >favori</b-switch
              >
              <b-radio
                v-model="filters.isPublished"
                name="name"
                native-value="true"
                type="is-success"
              >
                publié
              </b-radio>
              <b-radio
                v-model="filters.isPublished"
                name="name"
                native-value="false"
                type="default"
              >
                non publié
              </b-radio>
              <b-radio
                v-model="filters.isPublished"
                name="name"
                native-value=""
                type="default"
              >
                tous
              </b-radio>

              <b-button
                rounded
                type="is-ghost"
                size="is-medium"
                @click="resetFilters"
              >
                <font-awesome-icon
                  icon="fa-solid fa-arrow-rotate-left"
                  class="icon is-medium"
                />
              </b-button>
            </div>

            <!-- BOUTON CREER PRODUIT - COTE DROIT -->
            <div class="column is-4 is-flex is-justify-content-end">
              <b-button
                class=""
                label="Créer un produit"
                type="is-primary"
                size="is-medium"
                @click="isCardModalActive = true"
              />
            </div>
          </div>
          <!-- RECHERCHE PAR TAGS -->
          <div>
            <b-field grouped group-multiline>
              <div v-for="tag in filterTags" :key="tag.id" class="control">
                <b-tag
                  type="is-info is-light"
                  attached
                  aria-close-label="Close tag"
                  closable
                  @close="removeTagFilter(tag)"
                >
                  {{ tag.code }}
                </b-tag>
              </div>
            </b-field>
            <b-field class="my-5">
              <b-autocomplete
                v-model="tagName"
                :data="codeTags"
                placeholder="Filtrez selon les tags"
                icon="magnify"
                clearable
                @select="(option) => addFilterTag(option)"
              >
                <template #empty>Aucun résultat trouvé</template>
              </b-autocomplete>
            </b-field>
          </div>
          <!-- LISTE PRODUITS -->
          <div class="columns is-multiline my-2">
            <div
              class="column is-4"
              v-for="product in products"
              :key="product.id"
            >
              <div
                class="card is-clickable"
                :class="{ 'has-background-success-light': product.isPublished }"
                @click="updateModale(product.id)"
              >
                <header class="card-header">
                  <p class="card-header-title">{{ product.title }}</p>
                  <span class="icon m-3">
                    <font-awesome-icon
                      icon="fa-solid fa-star"
                      class="icon is-medium is-pulled-right is-relative has-text-warning"
                      v-if="product.isFavorite"
                    />
                  </span>
                </header>
                <div class="card-image">
                  <div class="tag-pdt">
                    <b-field
                      v-for="tag in product.tags"
                      :key="tag.id"
                      class="mt-1 ml-1 mb-0 mr-0"
                    >
                      <b-tag type="is-info"> {{ tag.code }} </b-tag>
                    </b-field>
                  </div>
                  <figure class="image is-4by3">
                    <img
                      v-if="product.pictures"
                      :src="getProductImg(product)"
                      alt="Placeholder image"
                    />
                    <img
                      v-else
                      src="https://bulma.io/images/placeholders/1280x960.png"
                      alt="Placeholder image"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <!-- PAGINATION -->
          <b-pagination
            :total="pagination.totalItems"
            v-model="pagination.currentPage"
            :order="'is-centered'"
            :size="'is-mall'"
            :per-page="pagination.pagingSize"
            :icon-prev="'chevron-left'"
            :icon-next="'chevron-right'"
            aria-next-label="Suivante"
            aria-previous-label="Précédente"
            aria-page-label="Page"
            aria-current-label="Page courrante"
            @change="getProductByPage"
          ></b-pagination>
        </div>

        <!-- COLONNE DE TAGS -->
        <div class="column is-5 box m-3">
          <TagList :tags="tags" />
        </div>
      </div>
    </div>

    <!-- MODALE -->
    <CreateProductModal
      :tags="tags"
      :isModalActive="isCardModalActive"
      @close="closeModale()"
      @resetProducts="allProducts()"
      :id="productId"
    />
  </div>
</template>

<script>
import * as token from "@/utils/token";
import * as product from "@/api/product";
import CreateProductModal from "@/views/admin/components/CreateProductModal.vue";
import TagList from "@/views/admin/components/TagList.vue";
import * as tagApi from "@/api/tag";

export default {
  name: "ProductsView",
  components: {
    CreateProductModal,
    TagList,
  },
  data() {
    return {
      // Modal
      isCardModalActive: false,
      // Liste des tags
      tags: [],
      filterTags: [],
      tagName: "",
      // Liste des produits
      products: [],
      // Pagination
      pagination: {
        currentPage: 1,
        totalItems: 0,
        totalPages: 1,
        pagingSize: 0,
      },
      // Recherche multicritère
      filters: {
        isFavorite: false,
        isPublished: null,
        search: "",
        tagCodes: "",
      },
      // Edition produit
      productId: null,
    };
  },
  created() {
    this.allProducts();
    this.allTags();
  },

  watch: {
    filters: {
      async handler(newValue) {
        if (newValue == null || newValue == undefined) return;
        this.pagination.currentPage = 1;
        await this.allProducts();
      },
      deep: true,
    },
  },

  methods: {
    closeModale() {
      this.isCardModalActive = false;
      this.productId = null;
    },
    updateModale(id) {
      this.isCardModalActive = true;
      this.productId = id;
    },
    getName() {
      return token.getName();
    },
    logout() {
      token.removeAuthenticationItems();
      this.$router.push({ name: "admin-login" });
    },

    /** Charge les tags */
    async allTags() {
      let response = [];
      response = await tagApi.findAll();
      this.tags = response;
      this.tags.push({ code: "", label: "" });
    },

    /** Ajoute un tag sur les filtres de recherche */
    addFilterTag(option) {
      if (option === null) return;
      let tagChoosen = this.tags.find((tag) => tag.code === option);
      // on l'ajoute aux filtres s'il est absent
      let tagAlreadyChoosen = this.filterTags.find(
        (tag) => tag.code === option
      );
      if (tagAlreadyChoosen) return;
      // prévisualisation des tags souhaités
      this.filterTags.push(tagChoosen);
      // filtre de recherche
      this.filters.tagCodes = this.filterTags.map((ft) => ft.code).join();
    },

    /** Retire un tag du filtre de recherche */
    removeTagFilter(tag) {
      if (tag === null) return;
      let tagToDeleteIndex = this.filterTags.indexOf(tag);
      // prévisualisation des tags souhaités
      this.filterTags.splice(tagToDeleteIndex, 1);
      // filtre de recherche
      this.filters.tagCodes = this.filterTags.map((ft) => ft.code).join();
    },

    /** Charge les produits */
    async allProducts() {
      let paginatedResponse = await product.findAll(
        this.filters,
        this.pageIndex
      );
      if (paginatedResponse.ressources == undefined) {
        this.products = [];
        this.resetFilters();
        this.resetPagination();
        return;
      }
      this.products = paginatedResponse.ressources;
      this.pagination.currentPage = paginatedResponse.currentPage + 1;
      this.pagination.totalItems = paginatedResponse.totalItems;
      this.pagination.totalPages = paginatedResponse.totalPages;
      this.pagination.pagingSize = paginatedResponse.pagingSize;
    },

    resetPagination() {
      this.pagination = {
        currentPage: 1,
        totalItems: 0,
        totalPages: 1,
        pagingSize: 0,
      };
    },

    /** Réinitialise les filtres de recherches de produits */
    resetFilters() {
      // Vérification si les filtres sont déjà réinitialisés
      if (
        this.filters.isFavorite === false &&
        (this.filters.isPublished === null ||
          this.filters.isPublished === false) &&
        this.filters.search === "" &&
        this.filters.tagCodes === ""
      )
        return;
      // Réinitialisation des filtres et de la pagination
      this.filters = {
        isFavorite: false,
        isPublished: null,
        search: "",
        tagCodes: "",
      };
      this.filterTags = [];
      this.pagination.currentPage = 1;
    },

    async getProductByPage(page) {
      if (page < 1 || this.pagination.currentPage > this.pagination.totalPages)
        return;
      await this.allProducts();
    },

    getProductImg(product) {
      return (
        "data:" +
        product.pictures[0].type +
        ";base64," +
        product.pictures[0].data
      );
    },
  },

  computed: {
    /** valorise la liste des codes des tags selon la saisie de l'usager */
    codeTags() {
      return this.tags
        .map((tag) => tag.code)
        .filter((code) => {
          let codeStr = code.toLowerCase();
          let userWriting = this.tagName.toLowerCase();
          return codeStr.indexOf(userWriting) >= 0;
        });
    },
    pageIndex() {
      return this.pagination.currentPage - 1;
    },
  },
};
</script>

<style lang="scss">
/* barre de navigation */
#admin-logo-navbar img {
  max-height: 3rem;
}
.border-bottom {
  border-bottom: whitesmoke 1px solid;
}
.tag-pdt {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  z-index: 1;
}
</style>
