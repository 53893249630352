<template>
  <div style="height: 100vh">
    <div class="columns is-vcentered" style="height: 100vh">
      <div class="column is-4 is-offset-4 is-vcentered">
        <div class="box">
          <b-field label="Pseudo" :type="{ 'is-success': !!pseudo }" horizontal>
            <b-input id="pseudo" v-model="pseudo" maxlength="30"></b-input>
          </b-field>

          <b-field label="Password" horizontal>
            <b-input
              type="password"
              id="password"
              v-model="password"
              password-reveal
            ></b-input>
          </b-field>
          <button
            @click="login"
            :disabled="pseudo == '' || password == ''"
            class="button is-primary"
          >
            {{ loginButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as userApi from "@/api/user";
import * as token from "@/utils/token";

export default {
  name: "LoginView",
  data: function () {
    return {
      loginButtonText: "Connexion",
      pseudo: "",
      password: "",
    };
  },
  methods: {
    async login() {
      await userApi.login(this.pseudo, this.password);
      if (token.getToken() !== null) {
        this.$router.push({ name: "admin-products" });
      }
    },
  },
};
</script>
