import Vue from "vue";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import App from "./App.vue";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faAt,
  faRightFromBracket,
  faStar,
  faArrowRotateLeft,
  faTrashCan,
  faEnvelope,
  faTriangleExclamation,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";
import {
  faFacebook,
  faInstagram,
  faEtsy,
} from "@fortawesome/free-brands-svg-icons";

library.add([
  faFacebook,
  faInstagram,
  faEtsy,
  faAt,
  faRightFromBracket,
  faStar,
  faStarEmpty,
  faArrowRotateLeft,
  faTrashCan,
  faEnvelope,
  faTriangleExclamation,
  faPlus,
]);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(Buefy);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
