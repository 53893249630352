import adminAxios from "@/axios/admin";
import * as toaster from "@/utils/toaster.js";
export const create = async (
  title,
  description,
  etsyLink,
  isFavorite,
  isPublished,
  tags
) => {
  const data = {
    title,
    description,
    etsyLink,
    isFavorite,
    isPublished,
    tags,
  };
  let product;
  await adminAxios.post("/products", data).then((response) => {
    product = response.data;
    toaster.toastMessage(
      "La fiche produit " + response.data.title + " a été créé 😀",
      "is-success"
    );
  });
  return product;
};

export const findAllToWeb = async (filters, page) => {
  let paginatedResponse;
  let params = {
    ...filters,
    page,
  };
  await adminAxios
    .get("/web/products", {
      params,
    })
    .then((response) => {
      paginatedResponse = response.data;
    });
  return paginatedResponse;
};

export const findAll = async (filters, page) => {
  let paginatedResponse;
  let params = {
    ...filters,
    page,
  };
  await adminAxios
    .get("/products", {
      params,
    })
    .then((response) => {
      paginatedResponse = response.data;
    });
  return paginatedResponse;
};

export const findById = async (id) => {
  let product;
  await adminAxios.get("/products/" + id).then((response) => {
    product = response.data;
  });
  return product;
};

export const update = async (
  id,
  title,
  description,
  etsyLink,
  isFavorite,
  isPublished,
  pictures,
  tags
) => {
  const data = {
    id,
    title,
    description,
    etsyLink,
    isFavorite,
    isPublished,
    pictures,
    tags,
  };
  await adminAxios.put("/products/" + id, data).then((response) => {
    toaster.toastMessage(
      "La fiche produit " + response.data.title + " a été modifiée 😀",
      "is-success"
    );
  });
};

export const deleteById = async (id) => {
  if (sessionStorage.getItem("isAdmin") == "false") {
    toaster.toastMessage(
      "Vous n'êtes pas autorisé à effectuer cette action",
      "is-danger"
    );
  } else {
    await adminAxios.delete("/products/" + id);
  }
};
