import { ToastProgrammatic as Toast } from "buefy";

export function toastMessage(message, type) {
  return Toast.open({
    message,
    position: "is-top",
    type,
    duration: 5000,
  });
}

// Fonction servant à concaténer les erreurs détaillées dans un toaster
export function multipleErrorToaster(errorDetails) {
  let message = "";
  for (const [key] of Object.entries(errorDetails)) {
    errorDetails[key].forEach((errorField) => {
      message += `${errorField} </br>`;
    });
  }
  toastMessage(message, "is-danger");
}
