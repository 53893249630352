<template>
  <div>
    <b-field class="file is-primary py-3">
      <b-upload class="file-label" @input="addPicture">
        <span class="file-cta">
          <b-icon class="file-icon" icon="upload"></b-icon>
          <span class="file-label">Click to upload</span>
        </span>
      </b-upload>
    </b-field>

    <!-- AFFICHAGE DES IMAGES UPLOADEES -->
    <div
      v-for="(onePicture, index) in pictures"
      :key="onePicture.viewUrl"
      class="pt-1 is-flex is-flex-direction-row is-justify-content-space-between"
    >
      <img
        :src="onePicture.viewUrl"
        :alt="onePicture.alt"
        class="image is-24x24 m-2"
      />
      <b-field label="Alt" label-position="on-border">
        <b-input
          type="text"
          v-model="onePicture.alt"
          @input="altChange"
          maxlength="150"
        >
        </b-input>
      </b-field>
      <span @click="changeMainPicture(onePicture)" class="px-3">
        <font-awesome-icon
          icon="fa-solid fa-star"
          class="icon is-medium"
          :class="[onePicture.main ? 'has-text-info	' : 'has-text-dark']"
        />
      </span>
      <b-button
        @click="removePicture(onePicture, index, pictures)"
        type="is-info"
      >
        <font-awesome-icon class="icon is-small" icon="fa-solid fa-trash-can" />
      </b-button>
    </div>

    <!-- AFFICHAGE DES IMAGES EXISTANTES -->

    <div
      v-for="(onePicture, index) in savedPicturesRead"
      :key="onePicture.id"
      class="pt-1 is-flex is-flex-direction-row is-justify-content-space-between"
    >
      <img
        :src="getImgSrc(onePicture)"
        :alt="onePicture.alt"
        class="image is-24x24 m-2"
      />
      <b-field label="Alt" label-position="on-border">
        <b-input
          type="text"
          v-model="onePicture.alt"
          @input="altChange"
          maxlength="150"
        >
        </b-input>
      </b-field>
      <span @click="changeMainPicture(onePicture)" class="px-3">
        <font-awesome-icon
          icon="fa-solid fa-star"
          class="icon is-medium"
          :class="[onePicture.main ? 'has-text-info	' : 'has-text-dark']"
        />
      </span>
      <b-button
        @click="removePicture(onePicture, index, savedPictures)"
        type="is-info"
      >
        <font-awesome-icon class="icon is-small" icon="fa-solid fa-trash-can" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { toastMessage } from "@/utils/toaster";

export default {
  name: "UploadPicture",
  props: {
    // Toute donnée entrante du composant (il n'a que les droit de 'lecture')
    savedPicturesRead: Array,
  },
  data() {
    return {
      pictures: [],
      savedPictures: [],
    };
  },
  watch: {
    pictures() {
      this.$emit("updatePictures", this.pictures);
    },
    savedPictures() {
      this.$emit("updateSavedPictures", this.savedPictures);
    },
    savedPicturesRead() {
      this.savedPictures = this.savedPicturesRead;
    },
  },
  methods: {
    getImgSrc(picture) {
      return "data:" + picture.type + ";base64," + picture.data;
    },
    altChange() {
      this.$emit("updatePictures", this.pictures);
      this.$emit("updateSavedPictures", this.savedPictures);
    },

    changeMainPicture(picture) {
      if (this.pictures) {
        this.pictures.map((element) => (element.main = false));
        picture.main = true;
        this.$emit("updateSavedPictures", this.savedPictures);
      }
      if (this.savedPictures) {
        this.savedPictures.map((element) => (element.main = false));
        picture.main = true;
        this.$emit("updatePictures", this.pictures);
      }
    },
    addPicture(file) {
      if (!file || !this.isPictureValid(file)) return;
      let newPicture = {
        alt: "",
        main: false,
        file,
        viewUrl: null,
      };
      newPicture.viewUrl = URL.createObjectURL(file);
      URL.revokeObjectURL(file); // free memory
      if (!this.pictures.length) {
        if (!this.savedPictures) {
          newPicture.main = true;
        } else if (!this.savedPictures.length) {
          newPicture.main = true;
        }
      }
      this.pictures.push(newPicture);
    },
    removePicture(picture, index, picturesList) {
      picturesList.splice(index, 1);
      this.onlyOneMainPicture(picture);
    },
    onlyOneMainPicture(picture) {
      if (picture.main && this.pictures.length > 0) {
        this.pictures[0].main = true;
      } else if (picture.main && this.savedPictures.length > 0) {
        this.savedPictures[0].main = true;
      }
      this.$emit("pictureRemoved");
    },
    isPictureValid(file) {
      if (!file.type.match(/image\/(?=png|jpg|jpeg)[jpgen]{3,4}/)) {
        toastMessage("Seules les images sont acceptées", "is-danger");
        return false;
      }
      if (file.size > 3000000) {
        toastMessage(
          "La taille de l'image doit être inférieure à 3MB",
          "is-danger"
        );
        return false;
      }
      if (file.size < 5000) {
        toastMessage(
          "La taille de l'image doit être supérieure à 5Ko",
          "is-danger"
        );
        return false;
      }
      return true;
    },
  },
};
</script>
