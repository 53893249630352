<template>
  <div class="container">
    <div
      class="maintenance is-flex is-flex-direction-column is-justify-content-center"
    >
      <div class="my-4">
        <!-- Icone -->
        <font-awesome-icon
          class="icon is-large"
          icon="fa-solid fa-triangle-exclamation"
          color="#7e7e7e"
        />
      </div>
      <div class="my-4">
        <!-- Texte -->
        <p class="title is-3">Page en travaux...</p>
        <p class="title is-6">
          Mes cours de crochet arrivent très prochainement !
        </p>
      </div>
      <div class="my-4">
        <!-- Retour -->
        <b-button rounded @click="getHomePage">Accueil</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreationsView",
  components: {},

  data() {
    return {
      // Constantes
      HOME_PAGE_ROUTE: "home",
    };
  },

  methods: {
    /** Navigation vers la home page */
    getHomePage() {
      this.$router.push({ name: this.HOME_PAGE_ROUTE });
    },
  },
};
</script>

<style lang="scss">
.maintenance {
  height: 100vh;
}
</style>
