<template>
  <header class="m-2">
    <div class="columns mx-2">
      <div class="column is-4 is-offset-4 my-3">
        <router-link :to="{ name: 'home' }"
          ><b-image alt="Sophie & stitch" :src="require('@/assets/logo.png')"
        /></router-link>
      </div>
    </div>
    <div class="mr-3 has-text-right">
      <a class="mx-2" :href="Links.INSTA_LINK" target="_blank"
        ><font-awesome-icon
          class="icon is-medium"
          icon="fa-brands fa-instagram"
          color="#535353"
      /></a>
      <a class="mx-2" :href="Links.FB_LINK" target="_blank"
        ><font-awesome-icon
          class="icon is-medium"
          icon="fa-brands fa-facebook"
          color="#535353"
      /></a>

      <a class="mx-2" :href="Links.ETSY_LINK" target="_blank"
        ><font-awesome-icon
          class="icon is-medium"
          icon="fa-brands fa-etsy"
          color="#535353"
      /></a>

      <a
        class="mx-2"
        :href="
          Links.MAIL_TO + '?subject=Bonjour, j\'aimerai échanger avec vous'
        "
        ><font-awesome-icon
          class="icon is-medium"
          icon="fa-solid fa-envelope"
          color="#535353"
      /></a>
    </div>
  </header>
</template>
<script>
import ConstantsLinks from "@/utils/ConstantsLinks.js";

export default {
  name: "MainHeader",
  data() {
    return { Links: ConstantsLinks };
  },
};
</script>
